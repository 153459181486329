<template>
  <div id="auth">
    <div class="left"></div>
    <div class="right">
      <router-link to="/scripts" class="logo">
        <img alt="Vue logo" :src="LogoWhite" />
      </router-link>
      <div class="content">
        <nav>
          <ul class="nav-list">
            <li class="nav-item">
              <router-link :to="{ name: 'SignIn' }">Existing User</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'SignUp' }">New to NRoom</router-link>
            </li>
          </ul>
        </nav>
        <vue-page-transition name="zoom" class="h-100">
          <router-view />
        </vue-page-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { LogoWhite } from "@/assets";
export default {
  name: "Auth",
  data() {
    return {
      LogoWhite,
    };
  },
};
</script>

<style lang="scss" scoped>
#auth {
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
}

.left {
  background: white url(~@/assets/images/auth-bg.png);
  background-size: contain;
  padding-left: 4vw;
  display: none;

  @media (min-width: 764px) {
    display: block;
  }
}

.right {
  padding: 3vw 4vw;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }

  .content {
    width: Min(100%, 300px);
    padding: 30px 0 0;
    position: relative;

    nav {
      margin-bottom: 25px;
      .nav-list {
        display: flex;
        list-style: none;
        font-size: 1.4rem;

        .nav-item {
          flex-grow: 1;
          text-align: center;
          width: 50%;

          a {
            text-decoration: none;
            color: white;
            padding: 11px 10px;
            font-weight: 500;
            display: block;
            border-bottom: 1px solid grey;

            &.router-link-active {
              border-bottom-color: white;
            }
          }
        }
      }
    }
  }
}

.logo {
  height: 70px;
  padding: 10px;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    object-fit: contain;
  }
}
</style>
